import axios from 'axios'

//配置
export const operate = data => {
  return axios.post('/delivery/time/update', data)
}

//获取配置信息
export const getConfigInfo = data => {
  return axios.post('/delivery/time/get', data)
}
