import axios from 'axios'
import qs from 'qs'

export const login = data => {
  return axios.post('/sys/login', qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 获取个人信息
export const getUserInfo = data => {
  return axios.get('/platform/user/info')
}

// 根据登录用户获取菜单数据
export const getMenusByUser = data => {
  return axios.get('/platform/user/getMenusByUser')
}

// 付款码
export const payOrder = data => {
  return axios.post('/purchase/pay/payOrder', data)
}
