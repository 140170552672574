import axios from 'axios'

//订单列表
export const getProductList = data => {
  return axios.post('/purchase/goods/querySupplierGoods', data)
}

//分类列表
export const getTypeList = params => {
  return axios.get('/purchase/goods/listGoodsClazz', { params })
}

//品牌列表
export const getBrandList = data => {
  return axios.post('/purchase/goods/listGoodsBrand', data)
}

//删除
export const delProduct = data => {
  return axios.post('/purchase/goods/batchDelete', data)
}

//商品信息
export const getGoodsInfo = params => {
  return axios.get(`/purchase/goods/info/${params.id}`)
}

//更新商品信息
export const updateGoodsInfo = data => {
  return axios.post(`/purchase/goods/update`, data)
}

//保存商品信息
export const saveGoods = data => {
  return axios.post('/purchase/goods/save', data)
}

//导入
export const projectImport = data => {
  return axios.post('/purchase/goods/uploadAndAnalysis', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

//批量上下架
export const batchUpdateState = data => {
  return axios.post('/purchase/goods/batchUpdateState', data)
}
