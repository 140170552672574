import axios from 'axios'
import utils from '../lib/utils.js'

//订单列表
export const getList = data => {
  return axios.post('/purchase/after-sale-order/listOrder', data)
}

//导出订单信息
export const exportList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/after-sale-order/exportAfterSaleOrder', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//订单详情
export const detail = params => {
  return axios.get(`/purchase/after-sale-order/getOrderDetail`, { params })
}

//更新收货信息
export const updateStoreSoppingCart = data => {
  return axios.post(`/afterOrder/updateStoreSoppingCart`, data)
}

//获取配送信息
export const getLogistics = data => {
  return axios.post(`/afterOrder/logistics`, data)
}

//同意申请
export const pass = data => {
  return axios.post(`/purchase/after-sale-order/approveApply`, data)
}

//拒绝申请
export const reject = data => {
  return axios.post(`/purchase/after-sale-order/rejectApply`, data)
}

//确认收货
export const confirmReceive = data => {
  return axios.post(`/purchase/after-sale-order/confirmReceive`, data)
}

//发货
export const deliver = data => {
  return axios.post(`/purchase/after-sale-order/deliver`, data)
}
