import actions from './actions'
import mutations from './mutations'

export default {
  namespaced: 'common',
  actions,
  mutations,
  state: {
    flowQueryType: 'first',
    unReadMessage: false
  }
}
