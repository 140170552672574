<template>
  <div class="no-permission">
    <img src="../../assets/static/noPermission.png" />
    <p>{{ NO_PERMISSION_TIP }}</p>
    <el-button style="margin-top: 30px" type="primary" @click="toPage"
      >{{ s }} 秒后返回首页</el-button
    >
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { NO_PERMISSION_TIP } from '@/constant/'
export default {
  data() {
    return {
      NO_PERMISSION_TIP,
      s: 5
    }
  },
  created() {
    this.start()
  },
  computed: {
    ...mapState('authorization', ['menus', 'firstRoute'])
  },
  methods: {
    start() {
      this.timer = setInterval(() => {
        --this.s
        if (this.s === 0) {
          this.toPage()
        }
      }, 1000)
    },
    toPage() {
      console.log(this.firstRoute)
      if (this.firstRoute) {
        this.$router.replace({ name: this.firstRoute })
      } else {
        this.$router.replace({ name: 'guest' })
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style scoped lang="scss">
.no-permission {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    margin-top: 50px;
    font-size: 16px;
    color: #000;
  }
}
</style>
