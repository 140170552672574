<template>
  <div class="asibe">
    <!-- <div class="logo">
      <span style="color: #fff; font-size: 22px; text-align: center"
        >采购管理平台</span
      >
    </div> -->

    <div @click="isCollapse = !isCollapse" class="iconWrap">
      <i v-if="isCollapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div>

    <el-menu
      :collapse="isCollapse"
      :collapse-transition="false"
      class="menu"
      :style="menus.length ? 'width:200px' : ''"
      :background-color="menuThemeConfig.backgroundColor"
      :text-color="menuThemeConfig.textColor"
      :active-text-color="menuThemeConfig.activeTextColor"
      :default-openeds="openKeys"
      :unique-opened="true"
      :default-active="menuDefaultActive || $route.name"
    >
      <template v-for="menu in menus">
        <el-menu-item
          :key="menu.menuId"
          :index="menu.url"
          v-if="!menu.children || !menu.children.length"
          @click="routerClick(menu.url || '', menu)"
        >
          <icon-svg
            v-if="menu.icon"
            :icon-class="menu.icon"
            className="svgClass"
            style="margin-right: 5px"
          />
          <template slot="title"> {{ menu.name }} </template>
        </el-menu-item>

        <el-submenu
          :key="menu.menuId"
          :index="menu.url"
          v-else-if="menu.children && menu.children.length"
        >
          <template slot="title">
            <icon-svg
              v-if="menu.icon"
              :icon-class="menu.icon"
              className="svgClass"
            />
            <span :title="menu.name">{{ menu.name }}</span>
          </template>

          <template v-for="subMenu in menu.children">
            <el-menu-item
              v-if="!subMenu.children || !subMenu.children.length"
              :key="subMenu.menuId"
              :index="subMenu.url"
              @click="routerClick(subMenu.url, subMenu)"
            >
              <router-link :to="{ name: subMenu.url }">
                {{ subMenu.name }}
              </router-link>
            </el-menu-item>

            <el-submenu
              :key="subMenu.menuId"
              :index="subMenu.url"
              v-else-if="subMenu.children && subMenu.children.length"
            >
              <template slot="title">
                <span :title="subMenu.name">{{ subMenu.name }}</span>
              </template>

              <template v-for="subSubMenu in subMenu.children">
                <el-menu-item
                  v-if="!subSubMenu.children || !subSubMenu.children.length"
                  :key="subSubMenu.menuId"
                  :index="subSubMenu.url"
                  @click="routerClick(subSubMenu.url, subSubMenu)"
                >
                  <router-link :to="{ name: subSubMenu.url }">
                    {{ subSubMenu.name }}
                  </router-link>
                </el-menu-item>

                <el-submenu
                  :key="subSubMenu.menuId"
                  :index="subSubSubMenu.url"
                  v-else-if="subSubMenu.children && subSubMenu.children.length"
                >
                  <template slot="title">
                    <span :title="subSubMenu.name">{{ subSubMenu.name }}</span>
                  </template>

                  <template v-for="subSubSubMenu in subSubMenu.children">
                    <el-menu-item
                      v-if="!subSubSubMenu.children.length"
                      :key="subSubSubMenu.menuId"
                      :index="subSubSubMenu.url"
                      @click="routerClick(subSubSubMenu.url, subSubSubMenu)"
                    >
                      <router-link :to="{ name: subSubSubMenu.url }">
                        {{ subSubSubMenu.name }}
                      </router-link>
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      openKeys: [],
      isCollapse: false
    }
  },

  computed: {
    menuDefaultActive() {
      return this.$route.query.menuDefaultActive
    },
    ...mapState('authorization', ['menus']),

    menuThemeConfig() {
      return {
        backgroundColor: '#1a1f24',
        textColor: '#fff',
        activeTextColor: '#fff'
      }
    }
  },
  methods: {
    routerClick(name, obj) {
      this.$router.push({ name })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-submenu__icon-arrow {
  font-size: 16px;
}

.asibe {
  flex-shrink: 0;
  background: #1a1f24;
  display: flex;
  // height: 100vh;
  margin-top: 50px;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  position: relative;

  .el-menu--collapse {
    width: 64px !important;
  }

  .iconWrap {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    z-index: 10;
    i {
      font-size: 20px;
      cursor: pointer;
    }
  }

  .svgClass {
    color: #c5c5c5;
  }
  .logo {
    position: absolute;
    left: 0;
    top: 0;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.menu {
  // height: calc(100% - 80px);
  // margin-top: 80px;
  border-color: #1a1f24;
}

::v-deep .el-submenu {
  .el-menu-item {
    a {
      padding-left: 8px;
    }
  }
  .el-submenu__title {
    span {
      margin-left: 13px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 120px;
    }
    i {
      color: #fff;
    }
  }
  span {
    font-size: 14px;
  }
}
.el-menu-item {
  font-size: 14px;

  a {
    color: #fff;
    font-size: 14px;
    margin-left: 6px;
  }

  &:hover {
    background: rgba(0, 133, 255, 0.5) !important;
    &::after {
      display: none;
    }
  }
  &.is-active {
    background: #0d5292 !important;
    a {
      color: #fff;
    }
    &::after {
      display: none;
    }
  }
}
</style>
