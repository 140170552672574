import axios from 'axios'

//订单列表
export const getList = data => {
  return axios.post('/purchase/group-manage/query', data)
}

//删除
export const delGroup = data => {
  return axios.post('/purchase/group-manage/delete', data)
}

//更新
export const updateGroup = data => {
  return axios.post(`/purchase/group-manage/update`, data)
}

//保存
export const saveGroup = data => {
  return axios.post(`/purchase/group-manage/add`, data)
}
