import axios from 'axios'
import utils from '../lib/utils.js'

// 获取发货信息
export const getLogInfo = params => {
  return axios.get('/purchase/middle-purchase/logistics', { params })
}

//获取商品采购列表
export const getProductList = data => {
  return axios.post('/purchase/middleman-goods/getGoodsListBySupply', data)
}

//加入购物车
export const addStoreSoppingCart = data => {
  return axios.post('/purchase/storeShoppingCart/add', data)
}

//采购库
export const listStoreSoppingCart = data => {
  return axios.post('/purchase/storeShoppingCart/list', data)
}

//采购库批量删除
export const deleteStoreSoppingCart = data => {
  return axios.post('/purchase/storeShoppingCart/delete', data)
}

//采购数量更改
export const updateStoreSoppingCartNum = data => {
  return axios.post('/purchase/storeShoppingCart/updateNum', data)
}

//更新收货信息
export const updateStoreSoppingCart = data => {
  return axios.post('/purchase/storeShoppingCart/update', data)
}

//采购库确认下单1
export const confirmUpdatePurchase = data => {
  return axios.post('/purchase/purchase/updatePurchase', data)
}
//采购库确认下单2
export const confirmSave = data => {
  return axios.post('/purchase/purchase/save', data)
}
//采购库确认下单3
export const confirmCartSave = data => {
  return axios.post('/purchase/purchase/cartSave', data)
}

//采购订单列表
export const getLogisticsData = data => {
  return axios.post('/purchase/purchase/list', data)
}

//采购明细
export const getOrderDetail = params => {
  return axios.get('/purchase/purchase/order/detail', { params })
}

//支付信息
export const getPayOrder = params => {
  return axios.get('/purchase/pay/getPayParam', { params })
}

//查询付款信息
export const getPayStatus = params => {
  return axios.get('/purchase/purchase/getPayStatus', { params })
}

//工行卡支付
export const cardFreePayment = params => {
  return axios.get('/purchase/pay/get', { params })
}

//工行卡支付签约
export const cardFreePaymentFormSubmit = data => {
  return axios.post('/purchase/pay/sign', data)
}

//无卡支付
export const noCardFreePayment = data => {
  return axios.post('/purchase/pay/payOrder', data)
}

//线下支付
export const payOfflineHandler = data => {
  return axios.post('/purchase/pay/payOffline', data)
}

//获取验证码
export const sendSMSCode = data => {
  return axios.post('/purchase/pay/sendSMSCode', data)
}

//支付手机验证码获取
export const verifyCode = params => {
  return axios.get('/purchase/pay/send/verify/code', { params })
}

//支付密码设置
export const setPassword = data => {
  return axios.post('/purchase/pay/pay/password', data)
}

//确定下单详情
export const confirmProductData = params => {
  return axios.get('/purchase/purchase/order/detail', { params })
}

//付款记录
export const queryPayRecord = params => {
  return axios.get('/purchase/pay/queryPayRecord', { params })
}

//修改订单
export const editOrder = params => {
  return axios.get('/purchase/purchase/order/detail', { params })
}

//删除订单
export const deleteOrder = params => {
  return axios.get(`/purchase/purchase/delete`, { params })
}

//线下支付列表
export const payOffLineList = params => {
  return axios.get('/purchase/payOffLineList', { params })
}

//子订单列表
export const childOrderList = params => {
  return axios.get('/purchase/sub-purchase/queryListByPurchaseNo', { params })
}

//子订单列表采购明细
export const getChildOrderDetail = params => {
  return axios.get('/purchase/sub-purchase-detail/queryListByPurchaseNo', {
    params
  })
}

//确认收货
export const confirmSendProduct = data => {
  return axios.post('/purchase/sub-purchase/confirmReceipt', data)
}

//采购流水
export const getFlowRecord = data => {
  return axios.post('/purchase/purchase-record/list', data)
}

//充值\还款记录
export const getOtherRecord = data => {
  return axios.post('/purchase/member-balance-record/list', data)
}

//售后订单列表
export const getAfterList = data => {
  return axios.post('/purchase/after-sale-order/listStoreAfterSaleOrder', data)
}

//导出订单信息
export const exportAfterList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/sub-purchase/afterOrder/exportList', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}
//同意申请
export const pass = data => {
  return axios.post(`/purchase/sub-purchase/afterOrder/pass`, data)
}

//拒绝申请
export const reject = data => {
  return axios.post(`/purchase/sub-purchase/afterOrder/reject`, data)
}

//撤销订单
export const cancelOrder = data => {
  return axios.post(`/purchase/after-sale-order/cancelAfterSale`, data)
}

//保存物流信息
export const saveLogisticsInfo = data => {
  return axios.post(`/purchase/after-sale-order/submitReturnLogistics`, data)
}

//申请售后
export const afterSell = data => {
  return axios.post(`/purchase/after-sale-order/applyAfterSale`, data)
}

//生成预订单
export const createdPreOrder = data => {
  return axios.post(`/purchase/pre-purchase/save`, data)
}

//购物车-生成预订单
export const createdPreOrderCart = data => {
  return axios.post(`/purchase/pre-purchase/cartSave`, data)
}

//门店预订单采购列表
export const getPreOrderList = data => {
  return axios.post('/purchase/pre-purchase/list', data)
}

//预订单订单详情
export const getPreOrderDetail = params => {
  return axios.get(`/purchase/pre-purchase-detail/order/detail`, { params })
}

//配送计划
export const getPreOrderPlan = params => {
  return axios.get(`/purchase/pre-purchase-distribution-plan/queryPlan`, {
    params
  })
}

//预订单导出
export const exportPreOrderList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/pre-purchase/export', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//终止预订单
export const stopPre = params => {
  return axios.get('/purchase/pre-purchase/abort', { params })
}

//售后订单详情
export const afterDetail = data => {
  return axios.post('/purchase/after-sale-order/getAfterSaleOrderInfo', data)
}

//门店确认收货
export const confirmReceive = data => {
  return axios.post('/purchase/after-sale-order/storeConfirmReceive', data)
}

//余额支付
export const balancePayHandler = data => {
  return axios.post('/purchase/pay/payBalance', data)
}

//采购订单申请退款
export const applyRefund = data => {
  return axios.post('/purchase/pay/applyRefund', data)
}

//获取验证码
export const getCode = params => {
  return axios.get('/purchase/pay/send/verify/code', { params })
}

//余额查询
export const queryBalance = params => {
  return axios.get('/purchase/member-balance/queryBalance', { params })
}
