import { Message } from 'element-ui'
import moment from 'moment'
import city from '@/assets/data/city'
import { BASE_URL } from '@/constant'
import { getProvince, getCity, getRegion } from '@/api/common'
const privateKey = `rluz9wmszcf`

const cityJson = city.map(item => {
  return {
    value: item.code,
    label: item.name,
    children: (item.city || []).map(item => {
      return {
        value: item.code,
        label: item.name,
        children: (item.area || []).map(item => {
          return {
            value: item.code,
            label: item.name
          }
        })
      }
    })
  }
})

export default {
  getImg(imgId) {
    const token = localStorage.getItem('token')
    return imgId
      ? BASE_URL + `/purchase/fileUpload/getImg?fdfsId=${imgId}&token=${token}`
      : ''
  },
  cityJson(p) {
    return !p ? cityJson : cityJson.find(_ => _.label === p)
  },
  isPhone(phone) {
    // return /^1[\d | *]{10}$/.test(phone)
    return /^1\d{10}$/.test(phone)
  },
  isLandline(val) {
    const reg = /^([0-9]{3,4}-)?[0-9]{7,8}$/
    return reg.test(val)
  },
  isEMail(mail) {
    return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(
      mail
    )
  },

  isVehicleNumber(val) {
    var xreg =
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/
    var creg =
      /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/
    if (val.length == 7) {
      return creg.test(val)
    } else if (val.length == 8) {
      return xreg.test(val)
    } else {
      return false
    }
  },

  isTel(tel) {
    return (
      /^0\d{2,3}-?[\d | *]{7,8}$/.test(tel) ||
      /^[\d | *]{7,8}$/.test(tel) ||
      /^1[\d | *]{10}$/.test(tel)
    )
  },
  isIDCard(idCard) {
    return (
      /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
        idCard
      ) || /^[\d | *]{18}$/.test(idCard)
    )
  },

  isUrl(url) {
    const reg =
      /^https?:\/\/(([a-zA-Z0-9_-])+(\.)?)*(:\d+)?(\/((\.)?(\?)?=?&?[a-zA-Z0-9_-](\?)?)*)*$/i
    return reg.test(url)
  },
  amount(value) {
    if (!value) return 0

    const num = +value / 100
    const strNum = num.toString()

    let len = 0

    if (strNum.indexOf('.') !== -1) {
      len = num.toString().split('.')[1].length
    }

    return num < 1
      ? num.toFixed(len > 3 ? 3 : len)
      : num.toFixed(len >= 3 ? 3 : len)
  },
  copyText(text) {
    const button = document.createElement('button')
    document.body.appendChild(button)

    const copyBtn = new ClipboardJS(button, {
      text: () => text
    })

    copyBtn.on('success', function (e) {
      Message.success('复制成功')
      e.clearSelection()
      document.body.removeChild(button)
    })
    copyBtn.on('error', function (e) {
      document.body.removeChild(button)
    })

    button.click()
  },
  debounce(fn, delay) {
    let timer = null
    return function (...args) {
      if (timer) clearTimeout(timer)
      timer = setTimeout(function () {
        fn.apply(this, args)
      }, delay)
    }
  },
  throttle(fn, delay) {
    let flag = true
    return function (...args) {
      if (!flag) return
      flag = false
      setTimeout(() => {
        fn.apply(this, args)
        flag = true
      }, delay)
    }
  },

  dateFormat(time, fmt = 'YYYY-MM-DD HH:mm') {
    if (!time) return '-'

    let date = ''
    if (typeof time === 'number') {
      date = new Date(time.toString().length === 10 ? time * 1000 : time)
    } else {
      date = new Date(time.toString().replace(/-/g, '/'))
    }

    return moment(date).format(fmt)
  },
  sha256Encryption(pass) {
    const sha256 = require('js-sha256').sha256
    return sha256(pass + privateKey)
  },

  wait(timer = 0) {
    return new Promise(res => {
      setTimeout(res, timer)
    })
  },
  downFileAndFileName(data, title) {
    let fileName = '暂未命名'
    if (data.headers && data.headers['content-disposition']) {
      const disposition = data.headers['content-disposition']

      fileName = decodeURIComponent(
        disposition.split(';')[1].split('filename=')[1]
      )
    }

    if (title) {
      fileName = title
    }

    this.downFile(data.data, fileName)
  },
  downFile(buff, title, isLink = false) {
    if ('msSaveOrOpenBlob' in navigator) {
      //ie使用的下载方式
      window.navigator.msSaveOrOpenBlob(new Blob([buff]), title || '')
      return
    }

    const url = isLink ? buff : window.URL.createObjectURL(new Blob([buff]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', title || '')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  },
  requestId() {
    var date = new Date()
    var y = date.getFullYear() //年
    var m = date.getMonth() + 1 //月
    var d = date.getDate() //日
    var H = date.getHours() //时
    var M = date.getMinutes() //分
    var S = date.getSeconds() //秒
    var SS = date.getMilliseconds() //毫秒
    var R = ''
    if (m < 10) {
      m = '0' + m
    }
    if (d < 10) {
      d = '0' + d
    }

    if (H < 10) {
      H = '0' + H
    }

    if (M < 10) {
      M = '0' + M
    }
    if (S < 10) {
      S = '0' + S
    }
    if (SS < 10) {
      SS = '0' + SS
    }

    for (let i = 0; i < 6; i++) {
      var radom = Math.floor(Math.random() * 10)

      R += radom
    }

    return y + m + d + H + M + S + SS + R
  },
  formatNum(num) {
    if (num) {
      // toLocaleString功能最多保留三位小数 而且是整数部分每三位加一个逗号
      const target = Number(Number(num).toFixed(2)).toLocaleString()
      if (num % 1 === 0) {
        return `${target}.00`
      } else {
        const len = num.toString().split('.')[1].length
        return len === 1 ? `${target}0` : target
      }
    } else {
      return 0
    }
  },
  async renderAddress({ provinceId, cityId, regionId, address = '' }) {
    let provinceName = '',
      cityName = '',
      regionName = ''

    try {
      const r1 = await getProvince()
      const r2 = await getCity(provinceId)
      const r3 = await getRegion(cityId)

      provinceName = r1.list.find(_ => _.id == provinceId).name

      cityName = r2.list.find(_ => _.id == cityId).name

      regionName = r3.list.find(_ => _.id == regionId).name
    } catch (e) {
      console.log(e)
    }
    return provinceName + cityName + regionName + address
  }
}
