import axios from 'axios'

//获取所有车辆
export const getCarAllList = params => {
  return axios.get(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle/queryVehicles'
      : `/purchase/vehicle/queryVehicles`,
    { params }
  )
}

//车辆分组列表
export const getCarGroupList = params => {
  return axios.get(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle-grouping/queryGroup'
      : `/purchase/vehicle-grouping/queryGroup`,
    { params }
  )
}

//车辆列表
export const getCarList = data => {
  return axios.post(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle/list'
      : `/purchase/vehicle/list`,
    data
  )
}

//删除车辆
export const delCar = params => {
  return axios.get(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle/delete'
      : `/purchase/vehicle/delete`,
    { params }
  )
}

//编辑车辆
export const updateCar = data => {
  return axios.post(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle/update'
      : `/purchase/vehicle/update`,
    data
  )
}
//添加车辆
export const saveCar = data => {
  return axios.post(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle/save'
      : `/purchase/vehicle/save`,
    data
  )
}

//保存分组
export const saveGroup = data => {
  return axios.post(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle-grouping/save'
      : `/purchase/vehicle-grouping/save`,
    data
  )
}

//编辑分组
export const updateGroup = data => {
  return axios.post(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle-grouping/update'
      : `/purchase/vehicle-grouping/update`,
    data
  )
}

//删除分组
export const delCarGroup = params => {
  return axios.get(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/logistics/middleman-vehicle-grouping/delete'
      : `/purchase/vehicle-grouping/delete`,
    { params }
  )
}

//关联门店分组
export const relateGroup = data => {
  return axios.post(`/purchase/vehicle/associated`, data)
}

//根据车辆查询可关联门店分组
export const getCarGroupAllList = params => {
  return axios.get(`/purchase/vehicle/queryById`, { params })
}
