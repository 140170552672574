import axios from 'axios'

//订单列表
export const getMemberList = params => {
  return axios.get('/sys/user/queryUserList', { params })
}

//用户添加
export const saveUser = data => {
  return axios.post('/sys/user/saveUser', data)
}

//用户修改
export const modifyUser = data => {
  return axios.post('/sys/user/modifyUser', data)
}

//用户删除
export const deleteUser = params => {
  return axios.get('/sys/user/deleteUser', { params })
}

//重置密码
export const resetPassword = data => {
  return axios.post(`/sys/user/password/reset`, data)
}
