export default {
  ['CLEAR_USER_INFO'](state) {
    state.userInfo = {}
    state.token = ''
  },
  ['SET_USER_INFO'](state, info) {
    state.userInfo = info
  },
  ['SET_TOKEN'](state, token) {
    state.token = token
  },
  ['SET_USER_MENU'](state, { menus, authBtn, originalMenus, firstRoute }) {
    state.menus = menus
    state.authBtn = authBtn
    state.originalMenus = originalMenus
    state.firstRoute = firstRoute
  },
  ['SET_MENU'](state, menus) {
    state.menus = menus
  }
}
