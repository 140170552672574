<template>
  <el-cascader
    size="small"
    style="width: 100%"
    v-model="v"
    :options="options"
    placeholder="请选择收货地址"
    clearable
    :props="props"
    @change="change"
  />
</template>

<script>
export default {
  name: 'AreaSelect',
  props: {
    value: [],
    currentItem: null
  },
  data() {
    const _this = this

    return {
      v: [],
      options: [],
      props: {
        lazy: true,
        async lazyLoad(node, resolve) {
          try {
            const { level, value } = node
            let r = ''
            if (level == 0) {
              r = await _this.$api.common.getProvince()
            } else if (level == 1) {
              r = await _this.$api.common.getCity(value)
            } else {
              r = await _this.$api.common.getRegion(value)
            }
            const nodes = r.list.map(item => ({
              value: item.id,
              label: item.name,
              leaf: level >= 2 && level < 3
            }))
            resolve(nodes)
          } catch (e) {
            console.log(e)
          }
        }
      }
    }
  },
  methods: {
    change(v) {
      this.$emit('update:value', v)
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.v = val
        } else {
          this.v = []
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss"></style>
