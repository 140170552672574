import store, { MODULE_AUTHORIZATION } from '@/store'

// 检查登录
const checkUser = async to => {
  const token = localStorage.getItem('token')
  return !token && to.name !== 'guest' && to.name !== 'payCode'
}

export default async (checkName, isRoute = false, currentRouter = {}) => {
  const isLogin = await checkUser(currentRouter)
  const originalMenus = store.state.authorization.originalMenus
  const authBtn = store.state.authorization.authBtn
  const route = originalMenus.find(_ => _.url === checkName)

  if (route) {
    // 给按钮赋值权限
    if (currentRouter.meta) {
      const routerButton = authBtn.filter(
        _ => _.parentId === route.menuId && _.type === 2
      )
      routerButton.forEach(_ => {
        currentRouter.meta[_.url] = _.status === 0
      })
    }
  }

  return {
    is404: !route,
    isLogin
  }
}
