import axios from 'axios'
import qs from 'qs'

//设置收款配置
export const operate = data => {
  return axios.post('/purchase/supplier/updatePayConfig', qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

//设置联系方式
export const setupPhone = data => {
  return axios.post(
    '/purchase/supplier/updateOfflinePhone',
    qs.stringify(data),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
  )
}

//获取收款配置
export const queryPayConfig = params => {
  return axios.get('/purchase/supplier/queryPayConfig', { params })
}
