import checkAuth from '@/lib/checkAuth'

export default router => {
  router.beforeEach(async (to, from, next) => {
    const { is404, isLogin, firstRoute } = await checkAuth(to.name, true, to)

    // 检查登录
    if (isLogin) {
      return next('/guest')
    } else {
      if (to.path == '/') {
        const userType = localStorage.getItem('userType')
        let path = ''

        if (userType == 'SUPPLIER') {
          path = '/classification'
        } else if (userType == 'MIDDLEMAN') {
          path = '/orderList'
        } else {
          path = '/supplier'
        }

        return next(path)
      }
    }

    // 登录重定向第一个界面
    // if (firstRoute) {
    //   return next({ name: firstRoute })
    // }

    // // 权限判断
    // if (to.name !== 'guest' && to.name !== 'noPermission') {
    //   if (is404) {
    //     return next('/noPermission')
    //   }
    // }

    next()
  })

  return router
}
