import Vue from 'vue'
import VueRouter from 'vue-router'
import baseRoutes from './routes'
import controller from './controller'
const { CURRENT_PLATFORM_TYPE } = require('@/constant/')

Vue.use(VueRouter)

const [routerPush, routerReplace] = [
  VueRouter.prototype.push,
  VueRouter.prototype.replace
]
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}
VueRouter.prototype.replace = function replace(location) {
  return routerReplace.call(this, location).catch(error => error)
}

export default async () => {
  const routes = await import(`@/pages/${CURRENT_PLATFORM_TYPE}/routes`)
  const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [...baseRoutes, ...routes.default]
  })

  return controller(router)
}
