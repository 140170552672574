<template>
  <div id="guest">
    <div class="wrap">
      <div class="logo">
        <h1>采购管理平台</h1>
      </div>
      <div class="form">
        <el-form :model="params" :rules="rules" ref="formEl">
          <el-form-item prop="mobile">
            <el-input
              v-model.trim="params.mobile"
              @keyup.enter.native="onSubmit"
              placeholder="请输入账号"
              size="medium"
            >
              <template slot="prefix">
                <i class="el-input__icon el-icon-user"></i>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              @keyup.enter.native="onSubmit"
              v-model.trim="params.password"
              placeholder="请输入密码"
              size="medium"
            >
              <template slot="prefix">
                <i class="el-input__icon el-icon-lock"></i
              ></template>
            </el-input>
          </el-form-item>

          <!-- <el-form-item prop="captcha">
            <div class="code-wrap">
              <el-input
                @keyup.enter.native="onSubmit"
                v-model.trim="params.captcha"
                size="medium"
                placeholder="请输入验证码"
              >
                <template slot="prefix">
                  <i class="el-input__icon el-icon-key"></i
                ></template>
              </el-input>
              <div class="codes">
                <img
                  width="80"
                  height="35"
                  :src="captchaPath"
                  @click="getCaptcha"
                />
              </div>
            </div>
          </el-form-item> -->

          <el-form-item>
            <el-button
              @keyup.enter.native="onSubmit"
              :loading="loading"
              type="primary"
              size="medium"
              @click="onSubmit"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_URL, MENU_ADMIN, MENU_SUPPLIER, MENU_MIDDLEMAN } from '@/constant'

export default {
  data() {
    const rules = {
      mobile: [{ required: true, message: '请输入账号' }],
      password: [{ required: true, message: '请输入密码' }]
    }
    return {
      rules,
      MENU_ADMIN,
      MENU_SUPPLIER,
      MENU_MIDDLEMAN,
      captchaPath: '',
      params: {
        mobile: '',
        password: ''
        // captcha: '',
        // uuid: ''
      },
      loading: false
    }
  },
  async created() {
    // this.getCaptcha()
  },

  methods: {
    currentObj(userType) {
      let menu = []
      let path = ''

      if (userType == 'SUPPLIER') {
        menu = this.MENU_SUPPLIER
        path = 'storeInfoManage'
      } else if (userType == 'MIDDLEMAN') {
        menu = this.MENU_MIDDLEMAN
        path = 'supplierMaintenance'
      } else if (userType == 'PURCHASER') {
        menu = []
        path = 'productPurchase'
      } else {
        menu = this.MENU_ADMIN
        path = 'supplier'
      }

      return { menu, path }
    },
    async onSubmit() {
      this.$refs.formEl.validate(async valid => {
        if (valid) {
          this.loading = true
          try {
            // const params = { ...this.params }

            // const res = await this.$api.login.login(params)

            // const { menu, path } = this.currentObj(res.userType)

            // await this.$store.dispatch(`authorization/SET_MENU`, menu)

            // localStorage.setItem('PURCHASER', JSON.stringify(menu))
            // localStorage.setItem('userType', res.userType)
            // localStorage.setItem('token', res.token)

            // this.$message.success('登录成功')

            // this.$router.push({
            //   name: path
            // })

            const params = { ...this.params }

            const res = await this.$api.login.login(params)
            localStorage.setItem('userType', res.userType)
            localStorage.setItem('token', res.token)

            const { path } = this.currentObj(res.userType)
            await this.$store.dispatch(`authorization/SET_MENU`)
            this.$message.success('登录成功')
            this.$router.push({
              name: path
            })
          } catch (e) {
            console.log(e)
          } finally {
            this.getCaptcha()
            this.loading = false
          }
        }
      })
    },
    getCaptcha() {
      this.params.uuid = this.randNum(32)
      this.captchaPath = BASE_URL + '/captcha.jpg?uuid=' + this.params.uuid
    },

    randNum(n) {
      var rnd = ''
      for (var i = 0; i < n; i++) rnd += Math.floor(Math.random() * 10)
      return rnd
    }
  }
}
</script>
<style scoped lang="scss">
#guest {
  background: url('../../assets/image/guestBg.png') no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo {
    display: flex;
    align-items: center;
    img {
      flex-shrink: 0;
    }
    h1 {
      font-family: MicrosoftYaHei-Bold;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 52px;
      letter-spacing: 0px;
      color: #333333;
    }
  }
  .wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 540px;
    height: 400px;
    background: #fff;
  }
  .form {
    margin-top: 60px;
    width: 380px;
    ::v-deep .el-form-item {
      margin-bottom: 30px;
    }
    button {
      width: 100%;
    }
  }

  .code-wrap {
    display: flex;
    align-content: center;
    .codes {
      display: flex;
      justify-items: center;
      align-items: center;
      img {
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
