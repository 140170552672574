import axios from 'axios'

//订单列表
export const getGroupList = params => {
  return axios.get('/goods/supplier-goods-grouping/queryGroupByName', {
    params
  })
}

//全部仓库
export const getGroupAllList = params => {
  return axios.get('/goods/supplier-goods-grouping/queryGroup', {
    params
  })
}

//删除
export const delGroup = params => {
  return axios.get('/goods/supplier-goods-grouping/delete', { params })
}

//更新
export const updateGroup = data => {
  return axios.post(`/goods/supplier-goods-grouping/update`, data)
}

//保存
export const saveGroup = data => {
  return axios.post(`/goods/supplier-goods-grouping/save`, data)
}
