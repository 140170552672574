<template>
  <div id="app">
    <span class="updateTime">{{ VUE_APP_UPDATE_TIME }}</span>
    <template v-if="hideLayout">
      <router-view />
    </template>
    <layout-container v-else>
      <layout-menu slot="menu" v-if="!hideSlideMenu" />
      <layout-header slot="header" v-if="!hideMenu" />
      <router-view></router-view>
    </layout-container>
  </div>
</template>

<script>
import LayoutContainer from './Container'
import LayoutHeader from './Header'
import LayoutMenu from './SlideMenu'
import { MENU_ADMIN, MENU_SUPPLIER, MENU_MIDDLEMAN } from '@/constant'
export default {
  name: 'App',
  data() {
    return {
      MENU_ADMIN,
      MENU_SUPPLIER,
      MENU_MIDDLEMAN,
      token: '',
      VUE_APP_UPDATE_TIME: process.env.VUE_APP_UPDATE_TIME
    }
  },
  components: {
    LayoutHeader,
    LayoutContainer,
    LayoutMenu
  },
  async created() {
    if (location.hash.indexOf('?token=') > -1) {
      const params = {}

      const search = location.hash.split('guest')[1]

      search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (params[k] = v))

      localStorage.setItem('token', params.token)
      localStorage.setItem('userType', params.userType)
      localStorage.setItem(
        'userName',
        params.userName ? decodeURIComponent(params.userName) : '用户'
      )

      const { path, menu } = this.currentObj(params.userType)

      await this.$store.dispatch(`authorization/SET_MENU`, menu)
      this.$router.replace({
        name: path
      })
    } else {
      if (localStorage.getItem('PURCHASER')) {
        this.$store.dispatch(
          `authorization/SET_MENU`,
          JSON.parse(localStorage.getItem('PURCHASER') || '[]')
        )
      }
    }
  },
  methods: {
    currentObj(userType) {
      let menu = []
      let path = ''

      if (userType == 'SUPPLIER') {
        menu = this.MENU_SUPPLIER
        path = 'storeInfoManage'
      } else if (userType == 'MIDDLEMAN') {
        menu = this.MENU_MIDDLEMAN
        path = 'supplierMaintenance'
      } else if (userType == 'PURCHASER') {
        menu = []
        path = 'productPurchase'
      } else {
        menu = this.MENU_ADMIN
        path = 'supplier'
      }

      return { menu, path }
    }
  },
  computed: {
    hideLayout() {
      return this.$route.meta.hideLayout
    },
    hideMenu() {
      return this.$route.meta.hideMenu
    },

    hideSlideMenu() {
      return this.$route.meta.hideSlideMenu
    }
  }
}
</script>

<style lang="scss">
#app {
  .updateTime {
    opacity: 0;
    height: 0;
    position: absolute;
  }
  a {
    text-decoration: none;
    user-select: none;
  }

  width: 100%;
  height: 100%;
  .page-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100001;
    background-color: #fff;
  }

  .page-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: 100001;
    background-color: #fff;
  }

  .page-loading-cnt {
    height: 30%;
  }

  .page-loading-circle {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 6px solid rgb(243, 243, 243);
    border-radius: 100%;
    box-sizing: border-box;
    animation: loading-circle 0.3s infinite linear;

    @keyframes loading-circle {
      0% {
        transform: rotate3d(0, 0, 0, 0);
      }
      100% {
        transform: rotate3d(0, 1, 0, 180deg);
      }
    }
  }

  .page-loading-desc {
    margin: 16px 0 0;
    text-align: center;
    line-height: 1;
    color: rgb(172, 172, 172);
  }
}
</style>
