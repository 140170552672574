<template>
  <el-dialog
    append-to-body
    :close-on-click-modal="false"
    width="450px"
    title="设置余额支付密码"
    :visible.sync="show"
  >
    <!-- <p class="tipsWrap">当前未设置支付密码，请设置支付密码后，再进行支付。</p> -->
    <el-form
      ref="form"
      size="small"
      :rules="rules"
      :model="form"
      label-width="120px"
    >
      <el-form-item label="设置支付密码：" prop="password1">
        <el-input
          placeholder="请输入密码"
          show-password
          type="password"
          v-model.trim="form.password1"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="确认支付密码：" prop="password">
        <el-input
          placeholder="请确认密码"
          show-password
          @keyup.native.enter="submitForm"
          type="password"
          v-model.trim="form.password"
        >
        </el-input>
      </el-form-item>

      <el-form-item label="验证码：" prop="verifyCode">
        <el-input
          style="width: 175px; margin-right: 10px"
          v-model="form.verifyCode"
          placeholder="请输入短信验证码"
        ></el-input>

        <el-button
          style="width: 102px"
          size="mini"
          @click="getCodeHandler"
          type="primary"
          :disabled="!!numberTxt"
          >{{ currentNumTxt }}
        </el-button>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="submitForm"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    const pwValid1 = (rule, value, callback) => {
      // const pdTest =
      //   /^(?=.*[~!@#$%^&*()_?])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/
      if (value === '') {
        callback(new Error('请输入支付密码'))
      } else {
        callback()
        // if (!pdTest.test(value)) {
        //   callback(
        //     new Error(
        //       '密码由8-16位的大小写字母、数字以及特殊符号(仅支持下列特殊字符：~!@#$%^&*()_?)组成'
        //     )
        //   )
        // } else {
        //   callback()
        // }
      }
    }

    const pwValid2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入确认密码'))
      } else if (value !== this.form.password1) {
        callback(new Error('两次输入密码不一致,请确认并重新输入'))
      } else {
        callback()
      }
    }
    return {
      btnLoading: false,
      codeTimer: null,
      numberTxt: '',
      form: {
        password1: '',
        password: ''
      },
      rules: {
        password1: [{ validator: pwValid1, trigger: 'blur', required: true }],
        password: [{ validator: pwValid2, trigger: 'blur', required: true }],
        verifyCode: [
          { trigger: 'blur', required: true, message: '请输入验证码' }
        ]
      }
    }
  },
  computed: {
    currentNumTxt() {
      return !this.numberTxt ? '获取验证码' : this.numberTxt + 's'
    }
  },
  methods: {
    getCodeHandler() {
      if (this.codeTimer) return
      this.getSmsCode()
    },
    async getSmsCode() {
      try {
        const res = await this.$api.productPurchase.verifyCode()

        this.$message.success('验证码已发送')
        this.form.verifyId = res.result.verifyId

        this.numberTxt = 30
        this.codeTimer = setInterval(() => {
          this.numberTxt -= 1

          if (this.numberTxt <= 0) {
            clearInterval(this.codeTimer)
            this.codeTimer = null
            this.numberTxt = ''
          }
        }, 1000)
      } catch (e) {
        console.log(e)
        clearInterval(this.codeTimer)
        this.codeTimer = null
        this.numberTxt = ''
      }
    },
    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.btnLoading = true

          try {
            await this.$api.productPurchase.setPassword({
              verifyId: this.form.verifyId,
              verifyCode: this.form.verifyCode,
              password: this.form.password
            })
            this.$message.success('保存成功！')

            this.show = false
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.tipsWrap {
  margin-bottom: 16px;
  font-weight: bold;
}
</style>
