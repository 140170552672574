<template>
  <el-header class="header" height="50px">
    <div class="header-left">
      <img
        src="@/assets/image/logo.png"
        style="height: 22px; width: 190px"
        alt=""
      />
    </div>

    <div class="header-right">
      <template v-if="userType == 'PURCHASER'">
        <div class="linkWrap">
          <el-link @click="toPage('first')" icon="el-icon-notebook-1"
            >采购流水</el-link
          >
          <el-link @click="toPage('second')" icon="el-icon-files"
            >充值/还款记录</el-link
          >
        </div>

        <el-menu default-active="1" class="el-menu-demo" mode="horizontal">
          <el-submenu index="1">
            <template slot="title">{{ username || 'admin' }}</template>
            <el-menu-item @click="passwordVisible = true"
              >修改密码</el-menu-item
            >
            <el-menu-item @click="logout">退出</el-menu-item>
          </el-submenu>
        </el-menu>
      </template>

      <template v-else>
        <el-link
          style="position: relative"
          @click="toMessage"
          icon="el-icon-chat-line-round"
        >
          <i v-show="unReadMessage" class="redDot"></i>
          通知信息</el-link
        >
        <el-link
          class="out"
          icon="el-icon-switch-button"
          :underline="false"
          @click="logout"
          >退出系统</el-link
        >
      </template>
    </div>

    <PasswordModal :visible.sync="passwordVisible" v-if="passwordVisible" />
  </el-header>
</template>

<script>
import PasswordModal from '@/pages/index/productPurchase/components/PasswordModal'

export default {
  components: {
    PasswordModal
  },
  props: {},
  data() {
    return {
      passwordVisible: false
    }
  },
  computed: {
    unReadMessage() {
      return this.$store.state.common.unReadMessage
    },
    userType() {
      return localStorage.getItem('userType')
    },
    matched() {
      return this.$route.matched
    },
    username() {
      return localStorage.getItem('userName')
    }
  },
  created() {
    if (
      this.userType != 'PURCHASER' &&
      this.userType !== 'null' &&
      this.$route.path !== '/'
    ) {
      this.$store.dispatch(`common/SET_MESSAGE`)
    }
  },
  watch: {},
  methods: {
    toPage(type) {
      this.$store.dispatch(`common/SET_TYPE`, type)
      this.$router.replace({ name: 'turnover' })
    },
    toMessage() {
      this.$router.push({
        name: 'notification'
      })
    },
    async logout() {
      await this.$store.dispatch(`authorization/ACT_LOGOUT`, null)

      this.$router.push({ name: 'guest' })
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 20px 0 10px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin-bottom: 20px;
  .header-right {
    display: flex;
    align-items: center;
    color: #333;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    .out {
      margin-left: 30px;
    }
  }

  ::v-deep .el-link {
    font-weight: bold;
    margin-left: 20px;

    i {
      font-size: 17px;
    }
  }

  .redDot {
    display: block;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    top: 0px;
    left: -13px;
  }

  ::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 50px !important;
    line-height: 51px !important;
    color: #333 !important;
  }

  ::v-deep .el-menu-item,
  .el-submenu__title {
    height: 50px !important;
    line-height: 54px !important;
  }

  ::v-deep .el-link--inner {
    position: relative !important;
  }
}
</style>
