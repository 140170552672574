import axios from 'axios'

//订单列表
export const getRoleList = params => {
  return axios.get('/role/user-role/queryRole', { params })
}

//删除
export const deleteRole = params => {
  return axios.get('/role/user-role/deleteRole', { params })
}

//添加
export const addRole = data => {
  return axios.post(`/role/user-role/addRole`, data)
}

//更新
export const modifyRole = data => {
  return axios.post(`/role/user-role/modifyRole`, data)
}

//当前角色权限列表查询
export const queryCurrentRoleInfo = roleId => {
  return axios.get(`/role/user-role/info/${roleId}`)
}

//角色权限列表查询
export const resourceAll = params => {
  return axios.get('/sys/menu/listMenus', { params })
}

//分配权限
export const permissionAssign = data => {
  return axios.post('/sys/type/save', data)
}
