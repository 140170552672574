import axios from 'axios'
import utils from '../lib/utils.js'

//订单列表
export const getList = params => {
  return axios.get('/purchase/middle-purchase/middleManPurchaseList', {
    params
  })
}

//导出订单信息
export const downloadMainStoreList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/middle-purchase/downloadMainStoreList', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//合并订单详情
export const getLogisticsData = data => {
  return axios.post('/purchase/middle-purchase/orderConsolidationPreview', data)
}

//合并保存
export const orderConsolidation = data => {
  return axios.post('/purchase/middle-purchase/orderConsolidation', data)
}

//订单详情
export const getOrderDetail = params => {
  return axios.get(`/purchase/queryAcceptGoods`, { params })
}

//采购详情
export const getDetailList = params => {
  return axios.get(`/purchase/middle-purchase/detail/list`, { params })
}

//发货信息
export const getLogistics = params => {
  return axios.get(`/purchase/middle-purchase/logistics`, {
    params
  })
}

//收款记录
export const queryReceipt = params => {
  return axios.get(`/purchase/middle-purchase/queryReceipt`, {
    params
  })
}

//付款记录
export const queryPayRecord = params => {
  return axios.get(`/purchase/pay/middleman/queryPayRecord`, {
    params
  })
}

//确认收款
export const confirmReceipt = data => {
  return axios.post('/purchase/middle-purchase/confirmOfflineCollection', data)
}

//核定收获图片上传
export const uploadPurchasePicture = data => {
  return axios.post('/purchase/middle-purchase/uploadPurchasePicture', data)
}

//中间商核定收货
export const checkAcceptGoods = data => {
  return axios.post('/purchase/middle-purchase/verify/receipt', data)
}

//发货详情
export const listPurchaseDetail = data => {
  return axios.post(`/purchase/middle-purchase-detail/listPurchaseDetail`, data)
}

//核定详情
export const queryAcceptGoods = params => {
  return axios.get(`/purchase/middle-purchase/verify/receipt/info`, { params })
}

//中间商发货
export const merchantSave = data => {
  return axios.post(`/purchase/middle-purchase/deliverGoods`, data)
}

//订单发货批量详情
export const getBatchData = data => {
  return axios.post(`/purchase/middle-purchase-detail/queryGoodsList`, data)
}

//中间商批量发货
export const sendBatchProduct = data => {
  return axios.post(`/purchase/middle-purchase/batch/send/out`, data)
}

//中间商修改物流
export const batchUpdateLogistics = data => {
  return axios.post(`/purchase/middle-purchase/batchUpdateLogistics`, data)
}
