import Vue from 'vue'
import App from './views/layouts/App.vue'
import routerController from './router'
import store from './store'
import InjectPlugin from '@/lib/inject'

// 兼容IE
if (process.env.NODE_ENV === 'production') {
  require('core-js/stable')
  require('regenerator-runtime/runtime')
}

Vue.use(InjectPlugin)

Vue.config.productionTip = false
;(async () => {
  const router = await routerController()
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})()
