import axios from 'axios'
import utils from '../lib/utils.js'
//列表数据
export const getStoreList = data => {
  return axios.post('/purchase/supplyStores/query', data)
}

//充值记录
export const getChargeRecord = data => {
  return axios.post('/purchase/member-balance-record/queryChargeRecord', data)
}

//额度申请记录
export const getApplyRecord = data => {
  return axios.post('/purchase/purchase-record/queryApplyRecord', data)
}

//消费记录
export const getConsumeRecord = data => {
  return axios.post('/purchase/purchase-record/queryConsumedRecord', data)
}

//编辑
export const editInfo = data => {
  return axios.post('/purchase/supplyStores/updateStoresPayAndGroup', data)
}

//修改门店分组
export const group = data => {
  return axios.post('/purchase/supplyStores/updateStoresGroup', data)
}

//申请额度
export const applyLimit = data => {
  return axios.post('/purchase/member-balance-order/applyCharge', data)
}

//余额充值记录查询
export const queryBalance = data => {
  return axios.post('/purchase/member-balance/queryBalance', data)
}

//获取申请状态
export const getStatus = data => {
  return axios.post('/purchase/supplyStores/storesPayAndGroupInfo', data)
}

//下载模板
export const exportChargeTemplate = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/supplyStores/down/chargeTemplate', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//导入模板
export const batchImport = data => {
  return axios.post('/purchase/supplyStores/down/batchImport', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
