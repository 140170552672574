import { unReadMessage } from '@/api/notification'

export default {
  ['SET_TYPE']: ({ commit }, data) => {
    commit('SET_TYPE', data)
  },
  ['SET_MESSAGE']: async ({ commit }) => {
    try {
      const res = await unReadMessage()

      commit('SET_MESSAGE', res.isMessage)
    } catch (e) {
      console.log(e)
    }
  }
}
