import axios from 'axios'

//列表数据
export const getList = params => {
  return axios.get('/purchase/supply-purchase/mainSupplierList', { params })
}

//采购详情
export const mainSupplierList = data => {
  return axios.post(
    '/purchase/middle-purchase-detail/listSupplyPurchaseDetail',
    data
  )
}

//确认收货
export const mainSupplierSave = data => {
  return axios.post('/purchase/middle-purchase/confirmReceipt', data)
}

//发货信息
export const logInfo = params => {
  return axios.get('/purchase/middle-purchase/logistics', { params })
}

//收货地址
export const getMerchantAddress = params => {
  return axios.get('/purchase/middle-purchase/getMerchantAddress', { params })
}

//下单
export const mainSupplierConfirmPay = data => {
  return axios.post('/purchase/middle-purchase/mainSupplierConfirmPay', data)
}
