import { Loading } from 'element-ui'
import { LOGIN_URL } from '@/constant/'

import { navList } from '@/api/common'

export default {
  ['SET_USER_INFO']: ({ commit }, data) => {
    commit('SET_USER_INFO', data || {})
  },
  ['ACT_LOGOUT']: ({ commit }) => {
    localStorage.clear()
    commit('CLEAR_USER_INFO')
  },
  ['SET_MENU']: async ({ commit }, data) => {
    // commit('SET_MENU', data)

    try {
      const res = await navList()

      if (res && res.menuList) {
        const recursion = item => {
          if (item.list && item.list.length) {
            item.children = item.list.map(_ => recursion(_))
          }
          return {
            name: item.name,
            url: item.url || '',
            menuId: item.menuId,
            children: item.children || undefined
          }
        }

        const targetData = res.menuList.map(_ => recursion(_))

        localStorage.setItem('PURCHASER', JSON.stringify(targetData))
        localStorage.setItem('PERMISSION', JSON.stringify(res.permissions))
        commit('SET_MENU', targetData)
      }
    } catch (e) {
      console.log(e)
    }
  }
}
