import axios from 'axios'

//列表
export const getList = data => {
  return axios.post('/purchase/middleman-goods/pageSupplierGoods', data)
}

//上架到采购库
export const uploadToStore = data => {
  return axios.post('/purchase/middleman-goods/saveGoods', data)
}

//批量上架到采购库
export const uploadToStoreBatch = data => {
  return axios.post('/purchase/middleman-goods/batchSaveGoods', data)
}
