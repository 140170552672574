export const BASE_URL = location.protocol + '//' + location.host

// 登出路径
export const LOGIN_URL =
  BASE_URL + location.pathname + location.search + '#/guest'

// 平台类型
export const INDEX = 'index' // 银商
export const GOVERNMENT = 'government' // 服务商

export const PLATFORM =
  location.pathname.indexOf(GOVERNMENT) !== -1 ? GOVERNMENT : INDEX

// 平台类型
export const INDEX_SYSTEM = 1 // 数据管理
export const GOVERNMENT_SYSTEM = 2 // 政府端

export const NO_PERMISSION_TIP = '您没有权限访问此页面'

// 当前平台
export const CURRENT_PLATFORM_TYPE = PLATFORM
export const CURRENT_SYSTEM =
  PLATFORM === INDEX ? INDEX_SYSTEM : PLATFORM === GOVERNMENT_SYSTEM

export const NO_FIND = '您访问的页面不存在'

export const MENU_ADMIN = [
  {
    name: '用户管理',
    url: '/supplier',
    icon: 'icon-user-manage',
    menuId: '1',
    children: [
      {
        url: 'supplier',
        menuId: '1-1',
        name: '供应商管理'
      },
      {
        url: 'middleman',
        menuId: '1-2',
        name: '中间商管理'
      },
      {
        url: 'classification',
        menuId: '1-3',
        name: '商品分类管理'
      }
    ]
  }
]

export const MENU_SUPPLIER = [
  {
    name: '门店管理',
    url: '/storeInfoManage',
    icon: 'icon-mendian',
    menuId: '1',
    children: [
      {
        url: 'storeInfoManage',
        menuId: '1-1',
        name: '门店信息管理'
      },
      {
        url: 'groupManage',
        menuId: '1-2',
        name: '门店分组管理'
      },
      {
        url: 'auditManage',
        menuId: '1-3',
        name: '审批管理'
      }
    ]
  },
  {
    name: '商品管理',
    url: '/brandList',
    icon: 'icon-shangpinguanli',
    menuId: '2',
    children: [
      {
        url: 'brandList',
        menuId: '2-1',
        name: '商品品牌'
      },
      {
        url: 'updownManage',
        menuId: '2-2',
        name: '商品上下架'
      },
      {
        url: 'groupList',
        menuId: '2-3',
        name: '商品仓库归属'
      },
      {
        url: 'clazzList',
        menuId: '2-4',
        name: '商品分类管理'
      },
      {
        url: 'productList',
        menuId: '2-5',
        name: '商品信息管理'
      }
    ]
  },
  {
    name: '供货管理',
    url: '/relateMiddleMan',
    icon: 'icon-jinhuoguanli',
    menuId: '3',
    children: [
      {
        url: 'operatorsConfig',
        menuId: '3-1',
        name: '商品优惠配置'
      },
      // {
      //   url: 'groupConfig',
      //   menuId: '3-2',
      //   name: '分组优惠配置'
      // },
      // {
      //   url: 'offlineConfig',
      //   menuId: '3-3',
      //   name: '线下收款配置'
      // },

      {
        url: 'relateMiddleMan',
        menuId: '3-4',
        name: '关联中间商'
      },
      {
        url: 'commodityWarehousing',
        menuId: '3-5',
        name: '商品入库'
      },
      {
        url: 'outboundGoods',
        menuId: '3-6',
        name: '商品出库'
      },
      {
        url: 'icstockbillEntry',
        menuId: '3-7',
        name: '出入库明细'
      },
      {
        url: 'goodsStock',
        menuId: '3-8',
        name: '商品库存信息'
      },
      {
        url: 'goodsStatisyics',
        menuId: '3-9',
        name: '商品统计信息'
      },
      {
        url: 'goodsInventory',
        menuId: '3-10',
        name: '商品盘点'
      }
    ]
  },
  {
    name: '物流管理',
    url: '/carManage',
    icon: 'icon-wuliu',
    menuId: '4',
    children: [
      {
        url: 'carManage',
        menuId: '4-1',
        name: '车辆管理'
      }
    ]
  },
  {
    name: '订单管理',
    url: '/orderList',
    icon: 'icon-dingdanguanli',
    menuId: '5',
    children: [
      {
        url: '',
        name: '采购订单',
        menuId: '5-1',
        children: [
          {
            url: 'orderList',
            menuId: '5-1-1',
            name: '全部订单明细'
          },
          {
            url: 'noSendOrderList',
            menuId: '5-1-2',
            name: '未发货订单管理'
          },
          {
            url: 'haveSendOrderList',
            menuId: '5-1-3',
            name: '已发货订单管理'
          },
          {
            url: 'orderQuery',
            menuId: '5-1-4',
            name: '未发货商品查询'
          },
          {
            url: 'orderDownLoad',
            menuId: '5-1-5',
            name: '发货单下载'
          }
        ]
      },
      {
        url: 'preOrderList',
        menuId: '5-2',
        name: '预定订单'
      },
      {
        url: 'afterOrderList',
        menuId: '5-3',
        name: '售后订单'
      }
    ]
  },

  {
    name: '报表管理',
    url: '/orderReport',
    icon: 'icon-baobiaoguanli',
    menuId: '6',
    children: [
      {
        url: 'orderPurchaseReport',
        menuId: '6-1',
        name: '采购订单统计表'
      },
      {
        url: 'orderRefundReport',
        menuId: '6-2',
        name: '退款订单统计表'
      }
    ]
  },
  {
    name: '系统管理',
    url: '/roleManage',
    icon: 'icon-xitongguanli',
    menuId: '7',
    children: [
      {
        url: 'roleManage',
        menuId: '7-1',
        name: '角色管理'
      },
      {
        url: 'memberManage',
        menuId: '7-2',
        name: '人员管理'
      },
      {
        url: 'deliveryTimeManage',
        menuId: '7-3',
        name: '配送时间管理'
      }
    ]
  }
]

export const MENU_MIDDLEMAN = [
  {
    name: '采购管理',
    url: '/supplierMaintenance',
    icon: 'icon-caigouguanli',
    menuId: '1',
    children: [
      {
        url: 'supplierMaintenance',
        menuId: '1-1',
        name: '供应商关系维护'
      },
      {
        url: 'supplierGoodsSelect',
        menuId: '1-2',
        name: '供应商商品入库'
      },
      {
        url: '',
        menuId: '1-3',
        name: '入库商品管理',
        children: [
          {
            url: 'goodsUpMaintenance',
            menuId: '1-3-1',
            name: '已上架商品管理'
          },
          {
            url: 'goodsDownMaintenance',
            menuId: '1-3-2',
            name: '未上架商品管理'
          }
        ]
      },
      {
        url: 'ruleConfig',
        menuId: '1-4',
        name: '采购规则设置'
      }
    ]
  },
  {
    name: '物流管理',
    url: '/carManage',
    icon: 'icon-wuliu',
    menuId: '4',
    children: [
      {
        url: 'carManage',
        menuId: '4-1',
        name: '车辆管理'
      }
    ]
  },
  {
    name: '订单管理',
    url: '/storeProcurementOrders',
    icon: 'icon-dingdanguanli',
    menuId: '2',
    children: [
      {
        url: 'storeProcurementOrders',
        menuId: '2-1',
        name: '门店订单明细'
      },
      {
        url: 'storeProcurementOrdersMerge',
        menuId: '2-2',
        name: '门店订单合并下单'
      },
      {
        url: 'storeProcurementOrdersSend',
        menuId: '2-3',
        name: '门店订单发货'
      },
      {
        url: 'storeProcurementOrdersManage',
        menuId: '2-4',
        name: '已发货订单管理'
      },
      {
        url: 'supplypurchase',
        menuId: '2-5',
        name: '供应商采购'
      },
      {
        url: 'orderDownLoad',
        menuId: '2-6',
        name: '发货单下载'
      }
    ]
  },
  {
    name: '系统管理',
    url: '/roleManage',
    icon: 'icon-xitongguanli',
    menuId: '3',
    children: [
      {
        url: 'roleManage',
        menuId: '3-1',
        name: '角色管理'
      },
      {
        url: 'memberManage',
        menuId: '3-2',
        name: '人员管理'
      }
    ]
  }
]
