import Vue from 'vue'
import '@/assets/css/custom-element.scss'
import '@/assets/css/element-variables.scss'

import {
  Header,
  Main,
  Aside,
  Container,
  Drawer,
  Menu,
  Submenu,
  Breadcrumb,
  BreadcrumbItem,
  Avatar,
  Timeline,
  TimelineItem,
  CheckboxButton,
  Popover,
  Progress,
  Button,
  Input,
  Select,
  Tabs,
  TabPane,
  Slider,
  MenuItem,
  Table,
  TableColumn,
  Image,
  TimeSelect,
  Pagination,
  Transfer,
  Dialog,
  Card,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Form,
  FormItem,
  Switch,
  TimePicker,
  Tree,
  Alert,
  Option,
  Divider,
  Upload,
  Link,
  InputNumber,
  ColorPicker,
  Steps,
  Step,
  RadioGroup,
  Radio,
  Cascader,
  DatePicker,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  CheckboxGroup,
  Icon,
  Message,
  Loading,
  MessageBox,
  RadioButton,
  Autocomplete,
  Badge,
  Notification
} from 'element-ui'

const coms = [
  Header,
  Main,
  Aside,
  Container,
  Drawer,
  Menu,
  Submenu,
  Breadcrumb,
  BreadcrumbItem,
  Avatar,
  Timeline,
  TimelineItem,
  CheckboxButton,
  Popover,
  Progress,
  Button,
  Input,
  Select,
  Tabs,
  TabPane,
  Slider,
  MenuItem,
  Table,
  TableColumn,
  Image,
  TimeSelect,
  Pagination,
  Transfer,
  Dialog,
  Card,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Form,
  FormItem,
  Switch,
  TimePicker,
  Tree,
  Alert,
  Option,
  Divider,
  Upload,
  Link,
  InputNumber,
  ColorPicker,
  Steps,
  Step,
  RadioGroup,
  Radio,
  Cascader,
  DatePicker,
  Checkbox,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tooltip,
  CheckboxGroup,
  RadioButton,
  Autocomplete,
  Badge,
  Icon
]

coms.forEach(com => {
  Vue.use(com)
})

Vue.prototype.$ELEMENT = { size: 'mini', zIndex: 3000 }

Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
