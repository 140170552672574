import axios from 'axios'
import utils from '../lib/utils.js'

//订单列表
export const getList = data => {
  return axios.post('/purchase/pre-purchase/supplier/list', data)
}

//导出订单信息
export const exportPreOrderList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/pre-purchase/supplier/export', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//更新收货信息
export const updateStoreSoppingCart = params => {
  return axios.post(`/preOrder/updateStoreSoppingCart`, { params })
}
