<template>
  <div>
    <div
      id="errWrap"
      style="text-align: center; margin-top: 20px; display: none"
    >
      <img style="width: 100px" src="@/assets/image/fail.png" alt="" />
      <p id="failMsg" style="color: #666; font-weight: bold"></p>
    </div>

    <div
      id="successWrap"
      style="text-align: center; margin-top: 20px; display: none"
    >
      <img style="width: 100px" src="@/assets/image/success.png" alt="" />
      <p id="successMsg" style="color: #666; font-weight: bold"></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    this.render()
  },

  methods: {
    render() {
      const params = {}

      const search = location.hash.split('payCode')[1]

      search.replace(/([^?&=]+)=([^&]+)/g, (_, k, v) => (params[k] = v))

      setTimeout(async () => {
        const sendData = {
          payType: 3,
          payAmt: params.payAmt,
          purchaseNo: params.purchaseNo,
          qrCode: params.qrCode,
          transactionId: params.transactionId,
          payRemark: params.payRemark || ''
        }

        localStorage.setItem('token', params.token)

        try {
          const k = await this.$api.login.payOrder(sendData)

          if (k.code == 0) {
            if (k.payParam) {
              let divbody = document.createElement('div')
              divbody.innerHTML = k.payParam
              document.body.appendChild(divbody)
              document.forms[0].submit()
            } else {
              document.querySelector('#successWrap').style.display = 'block'
              document.querySelector('#successMsg').innerHTML = '支付成功'
            }
          }
        } catch (e) {
          document.querySelector('#errWrap').style.display = 'block'
          document.querySelector('#failMsg').innerHTML = '支付失败'
          console.log(e)
        }
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss"></style>
