<template>
  <div class="page-com">
    <div class="filters-btns">
      <div
        v-if="$slots.headerLeft"
        class="filter"
        :style="{ marginBottom: $slots.headerInfo ? 0 : '5px' }"
      >
        <div
          class="select-wrap"
          :style="{
            maxHeight: !showMoreFilter || isShow ? '999px' : '39px'
          }"
        >
          <div ref="selectWrap">
            <slot name="headerLeft" ref="headerLeft"></slot>
          </div>
        </div>
        <div
          class="filter-right"
          :style="{ textAlign: height && height <= 39 ? 'left' : 'right' }"
        >
          <template v-if="showMoreFilter">
            <el-link v-if="height && height > 39" @click="isShow = !isShow"
              ><i
                :class="!isShow ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
              ></i
              >{{ !isShow ? '展开' : '收缩' }}</el-link
            >
          </template>

          <el-button type="primary" size="mini" plain @click="parentGetData">
            查询
          </el-button>
          <el-button plain @click="parentClear">重置</el-button>
        </div>
      </div>

      <div v-if="$slots.headerRight" class="btns">
        <div>
          <slot name="headerRight"></slot>
        </div>
      </div>
    </div>

    <div v-if="$slots.headerInfo" style="margin-bottom: 15px; margin-top: 10px">
      <slot name="headerInfo"></slot>
    </div>

    <div class="body-content">
      <slot></slot>
    </div>

    <div class="pagination" v-if="$slots.pagination">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    showMoreFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isShow: false,
      height: 0
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$refs.selectWrap) {
        this.getHeight()

        this.getHeight = this.$utils.debounce(this.getHeight, 500)

        window.addEventListener('resize', this.getHeight)
      }
    })
  },
  methods: {
    getHeight() {
      if (this.$refs.selectWrap) {
        this.height = this.$refs.selectWrap.offsetHeight
      }
    },
    parentGetData() {
      this.$parent.replaceData
        ? this.$parent.replaceData()
        : this.$parent.getData(true)
    },
    parentClear() {
      const filtersForm = this.$parent.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.$parent.clearParams &&
        this.$parent.clearParams(
          filtersForm ? filtersForm.$data.fields : undefined
        )
      this.$nextTick(() => {
        !this.$parent.replaceData ? this.$parent.getData(true) : false
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style lang="scss" scoped>
.page-com {
  display: flex;
  flex: 1;
  // height: 0;
  padding: 20px;

  flex-direction: column;
  .filters-btns {
    display: flex;
    justify-content: space-between;
    .btns {
      display: flex;
      // margin-left: 20px;
      flex-shrink: 0;
    }
  }
  .title-wrap {
    display: flex;
    min-height: 32px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .title {
      line-height: 16px;
      font-size: 16px;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #000000;
      // margin: 5px 0 5 0;
    }
  }

  .body-content {
    flex: 1;
    display: flex;
    height: 0;
    flex-direction: column;
    overflow: auto;
  }
}
.filter {
  padding-right: 20px;
  display: flex;
  margin-bottom: 5px;
  .select-wrap {
    overflow: hidden;
    transition: 0.1s linear;
  }
  .filter-right {
    width: 220px;
    flex-shrink: 0;
    text-align: left;
    button {
      margin-left: 8px;
    }
    ::v-deep .el-link.el-link--danger.is-underline:hover:after,
    .el-link.el-link--danger:after {
      border-color: #c7000a;
    }
  }
  ::v-deep .el-input,
  ::v-deep .el-input-number--small {
    width: 140px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 10px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
}
</style>
