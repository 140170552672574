import axios from 'axios'
import utils from '../lib/utils.js'

// 商品出入库
export const getCommodityList = data => {
  return axios.post(`/goods/supplier-goods-invoice-info/list`, data)
}

// 新增入库

export const addVehicleList = params => {
  return axios.post(`/goods/supplier-goods-invoice-info/create`, params)
}

// 导入入库单
export const commodityImport = data => {
  return axios.post('/goods/supplier-goods-invoice-info/import', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

// 查询详情
export const getVehicleList = params => {
  return axios.get(`/goods/supplier-goods-invoice-info/query/${params}`)
}

// 导出入库单

export const exportExel = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/goods/supplier-goods-invoice-info/export/${data}`, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 出入库明细列表
export const getGoodsList = data => {
  return axios.post(`/goods/supplier-goods-invoice-info/list-detail`, data)
}

// 导出明细
export const exportGoodExel = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/goods/supplier-goods-invoice-info/export-detail`, data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 商品销售统计

export const getPurchaseList = data => {
  return axios.post(`/purchase/goods/statistics-list`, data)
}

export const exportPurchaseExel = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/purchase/goods/statistics-export`, data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//商品库存信息
export const getProductList = data => {
  return axios.post(`/purchase/goods/querySupplierGoods`, data)
}
// 导出库存

export const exportInventoryExel = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/purchase/goods/export`, data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 盘点

export const postInventory = data => {
  return axios.post(`/purchase/goods/inventory`, data)
}

// 盘点列表

export const getInventory = data => {
  return axios.post(`/goods/supplier-goods-inventory-info/list`, data)
}

// 盘点导出
export const exportInventoryListExel = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/goods/supplier-goods-inventory-info/export`, data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 盘点差异
export const getInventorycy = data => {
  return axios.get(
    `/goods/supplier-goods-inventory-info/list-detail/${data}?page=1&limit=10000`
  )
}

// 查看盘点信息
export const getInventoryList = data => {
  return axios.get(
    `/goods/supplier-goods-inventory-info/list-detail/${data.id}?page=${data.page}&limit=${data.limit}`
  )
}

// 导出盘点信息
export const exportInfoExel = data => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/goods/supplier-goods-inventory-info/export-detail/${data}`, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

// 导入盘点数量

export const inventoryImport = (id, data) => {
  return axios.post(
    `/goods/supplier-goods-inventory-info/import-detail/${id}`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  )
}
