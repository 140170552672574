import axios from 'axios'
import utils from '../lib/utils.js'

//订单列表
export const getOrderList = params => {
  return axios.get('/purchase/supply-purchase/supplier/list', { params })
}

//导出订单信息
export const exportProject = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/supply-purchase/down/supplierList', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//商品详情导出
export const exportProjectDetail = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/supply-purchase/down/supplierList', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//收款详情
export const getAmountDetail = params => {
  return axios.get(`/purchase/order/list`, { params })
}

//订单详情
export const getOrderDetail = params => {
  return axios.get('/purchase/supply-purchase-detail', { params })
}

//配送详情
export const getPurchaseDetail = purchaseNo => {
  return axios.get(`/purchase/supply-purchase-detail/${purchaseNo}`)
}

//发货信息
export const getLogistics = params => {
  return axios.get(`/purchase/purchase/logistics`, { params })
}

//订单发货
export const sendProduct = data => {
  return axios.post(`/purchase/supply-purchase/send/out`, data)
}

//订单批量发货
export const sendBatchProduct = data => {
  return axios.post(`/purchase/supply-purchase/batch/send/out`, data)
}

//获取修改订单列表
export const getBatchOrderList = data => {
  return axios.post(`/purchase/supply-purchase/batchPurchaseQuery`, data)
}

//获取选中商品列表
export const goodsList = data => {
  return axios.post(`/purchase/supply-purchase/goodsList`, data)
}

//调整后数量
export const saveChagne = data => {
  return axios.post(`/purchase/supply-purchase/updateNum`, data)
}

//批量调整订单
export const batchAdjust = data => {
  return axios.post(`/purchase/supply-purchase/batchUpdateNum`, data)
}

//未发货商品列表
export const getOrderQueryList = data => {
  return axios.post(`/purchase/supply-purchase/notShippedGoods`, data)
}

//未发货商品查询导出
export const exportOrderQueryList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/supply-purchase/down/notShippedGoods', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//发货单列表
export const getOrderDownLoadList = params => {
  return axios.get(
    localStorage.getItem('userType') == 'MIDDLEMAN'
      ? '/purchase/middle-purchase/middle/shippedList'
      : `/purchase/supply-purchase/supplier/shippedList`,
    { params }
  )
}

//下载发货单
export const exportOrderDownLoadList = data => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        localStorage.getItem('userType') == 'MIDDLEMAN'
          ? 'purchase/middle-purchase/down/middle/shippedList'
          : `/purchase/supply-purchase/down/supplier/shippedList`,
        data,
        {
          responseType: 'blob'
        }
      )
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//批量发货根据采购单号查询
export const getBatchData = data => {
  return axios.post(`/purchase/supply-purchase-detail/queryGoodsList`, data)
}

//批量修改物流信息
export const updateLogistics = data => {
  return axios.post(`/purchase/supply-purchase/updateLogistics`, data)
}

//取消订单
export const cancelOrder = data => {
  return axios.post(`/purchase/supply-purchase/cancel`, data)
}
