import axios from 'axios'

//区域列表
export const getList = params => {
  return axios.get('/area/list', { params })
}

//新增区域
export const areaSave = data => {
  return axios.post('/area/save', data)
}

//编辑区域
export const areaUpdate = data => {
  return axios.post('/area/update', data)
}

//删除
export const areaDelHandler = data => {
  return axios.post('/area/del', data)
}
