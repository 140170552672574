import axios from 'axios'

//消息列表
export const listMessage = data => {
  return axios.post('/notify/message-notify/listMessage', data)
}

//是否有消息
export const unReadMessage = params => {
  return axios.get('/notify/message-notify/isMessage', { params })
}

//部分已读
export const partRead = data => {
  return axios.post('/notify/message-notify/partRead', data)
}

//全部已读
export const allRead = data => {
  return axios.post('/notify/message-notify/allRead', data)
}
