export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
      required: true
    },
    currentItem: {
      type: Object | Array,
      default: () => null
    }
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    if (!this.isCreated) this.init && this.init()
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    isCreated() {
      return !this.currentItem
    },
    title() {
      return this.isCreated ? '新增' : '编辑'
    }
  }
}
