import Home from '@/views/home/index.vue'
import Guest from '@/views/guest/index.vue'
import payCode from '@/views/payCode/index.vue'
import noPermission from '@/views/noPermission/index.vue'

export default [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/guest',
    name: 'guest',
    component: Guest,
    meta: {
      title: '登录',
      hideLayout: true
    }
  },
  {
    path: '/noPermission',
    name: 'noPermission',
    component: noPermission,
    meta: {
      title: '404',
      hideLayout: true
    }
  },
  {
    path: '/payCode',
    name: 'payCode',
    component: payCode,
    meta: {
      title: '付款码',
      hideLayout: true
    }
  }
]
