import axios from 'axios'

//列表
export const getList = data => {
  return axios.post('/purchase/supplier/page', data)
}

//关联中间商列表
export const getRelateSupplierList = data => {
  return axios.post('/purchase/supplier/pageMiddleman', data)
}

// 重置密码
export const resetPassword = data => {
  return axios.post('/sys/user/password/reset', data)
}

//新增供应商
export const supplierSave = data => {
  return axios.post('/purchase/supplier/add', data)
}

//修改供应商
export const supplierUpdate = data => {
  return axios.post('/purchase/supplier/update', data)
}

//查询供应商可关联的中间商信息
export const getSupplier = data => {
  return axios.post('/purchase/supplier/listAssociativeMiddleman', data)
}

//新增供应商关联中间商
export const addRelationMiddleman = data => {
  return axios.post('/purchase/supplier/addRelationMiddleman', data)
}

//解除供应商和中间商的关联
export const unrelate = relationId => {
  return axios.post(`/purchase/supplier/relieveRelation/${relationId}`)
}

//查询所有供货节点
export const listSupplyNode = data => {
  return axios.post(`/purchase/supplier/listSupplyNode`, data)
}

//获取供货节点信息
export const getCurrentNode = data => {
  return axios.post(`/purchase/supply-node/list`, data)
}

//修改供货节点信息
export const updateNode = data => {
  return axios.post(`/purchase/supply-node/update`, data)
}

//获取供应商信息
export const getById = id => {
  return axios.post(`/purchase/supplier/getById/${id}`)
}
