import axios from 'axios'

//列表
export const getList = data => {
  return axios.post('/purchase/middleman/page', data)
}

//新增中间商
export const middlemanSave = data => {
  return axios.post('/purchase/middleman/add', data)
}

//修改中间商
export const middlemanUpdate = data => {
  return axios.post('/purchase/middleman/update', data)
}

//查询中间商关联的供应商
export const pageSupplier = data => {
  return axios.post('/purchase/middleman/pageSupplier', data)
}

//获取中间商信息
export const getById = id => {
  return axios.post(`/purchase/middleman/getById/${id}`)
}
