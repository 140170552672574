import axios from 'axios'
import utils from '../lib/utils.js'

//订单列表
export const getDistributionOrderList = params => {
  return axios.get(`/purchase/weChant/list`, { params })
}

//导出订单信息
export const exportDistributionProject = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/weChant/downloadDeliverGoods', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//发货信息
export const getDistributionLogistics = data => {
  return axios.post(`/purchase/weChant/queryDeliver`, data)
}

//发货
export const sendProductDistribution = data => {
  return axios.post('/purchase/weChant/deliverGoods', data)
}

//批量发货
export const sendProductDistributionBatch = data => {
  return axios.post('/purchase/weChant/batchDeliverGoods', data)
}
