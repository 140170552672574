export default Vue => {
  Vue.directive('scroll', {
    inserted: (el, bind) => {
      el.addEventListener('scroll', function () {
        if (el.scrollHeight - el.clientHeight - el.scrollTop <= 0) {
          bind.value()
        }
      })
    }
  })

  Vue.directive('clear', {
    inserted: (el, bind) => {
      let first = true
      el.addEventListener('input', function () {
        if (first) {
          first = false
          const val = el.querySelector('.el-input__inner').value
          if (val.includes('**')) {
            el.querySelector('.el-input__inner').value = ''
          }
        }
      })
    }
  })

  Vue.directive('auth', {
    inserted: (el, bind, vnode) => {
      let permission = false

      if (
        JSON.parse(localStorage.getItem('PERMISSION') || '[]').includes(
          bind.value
        )
      ) {
        permission = true
      }

      if (!permission) el.parentNode.removeChild(el)
    }
  })

  Vue.directive('platform', {
    inserted: (el, bind, vnode) => {
      const ve = vnode.context
      const userInfo = ve.$store.state.authorization.userInfo
      let permission = userInfo && userInfo.platformId === 'client-government'
      if (userInfo.type === 0) {
        permission = false
      }

      if (permission) el.parentNode.removeChild(el)
    }
  })
}
