import * as common from './common'
import * as login from './login'
import * as productPurchase from './productPurchase'
import * as middleman from './middleman'
import * as supplier from './supplier'
import * as classification from './classification'
import * as orderList from './orderList'
import * as brandList from './brandList'
import * as distribution from './distribution'
import * as productList from './productList'
import * as groupList from './groupList'
import * as clazzList from './clazzList'
import * as operatorsConfig from './operatorsConfig'
import * as supplyAreaConfig from './supplyAreaConfig'
import * as offlineConfig from './offlineConfig'
import * as ruleConfig from './ruleConfig'
import * as supplierGoodsSelect from './supplierGoodsSelect'
import * as supplierMaintenance from './supplierMaintenance'
import * as purchaseGoodsMaintenance from './purchaseGoodsMaintenance'
import * as storeProcurementOrders from './storeProcurementOrders'
import * as supplypurchase from './supplypurchase'
import * as reportManagement from './reportManagement'
import * as memberManage from './memberManage'
import * as roleManage from './roleManage'
import * as deliveryTimeManage from './deliveryTimeManage'
import * as storeInfoManage from './storeInfoManage'
import * as auditManage from './auditManage'
import * as groupManage from './groupManage'
import * as preOrderList from './preOrderList'
import * as afterOrderList from './afterOrderList'
import * as carManage from './carManage'
import * as notification from './notification'
import * as commodity from './commodity'

export default {
  common,
  login,
  productPurchase,
  middleman,
  supplier,
  classification,
  orderList,
  brandList,
  distribution,
  productList,
  groupList,
  clazzList,
  operatorsConfig,
  supplyAreaConfig,
  offlineConfig,
  ruleConfig,
  supplierGoodsSelect,
  supplierMaintenance,
  purchaseGoodsMaintenance,
  storeProcurementOrders,
  supplypurchase,
  reportManagement,
  memberManage,
  roleManage,
  deliveryTimeManage,
  auditManage,
  storeInfoManage,
  groupManage,
  preOrderList,
  afterOrderList,
  carManage,
  notification,
  commodity
}
