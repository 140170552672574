<template>
  <el-container class="container-wrap">
    <slot name="menu"></slot>
    <el-container class="container">
      <slot name="header"></slot>
      <el-main>
        <el-breadcrumb separator="/">
          <template v-for="item in matched">
            <el-breadcrumb-item
              v-if="item.path"
              :key="item.path"
              :to="{ path: item.path }"
            >
              {{ item.meta.title }}
            </el-breadcrumb-item>
          </template>
        </el-breadcrumb>
        <slot></slot>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
  computed: {
    matched() {
      return this.$route.matched
    }
  }
}
</script>

<style scoped lang="scss">
.container-wrap {
  display: flex;
  ::v-deep .el-main {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0;
    margin: 10px;
    margin-top: 0;
  }

  .el-breadcrumb {
    margin: 10px 10px 0;

    ::v-deep .el-breadcrumb__inner {
      font-size: 14px;
      font-weight: bold;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #f3f3f3;
    min-width: calc(1200px - 200px);
  }

  .el-main {
    margin-top: 60px;
  }
}
</style>
