import axios from 'axios'
import { MODULE_AUTHORIZATION } from '@/actions'

import { LOGIN_URL } from '@/constant/'
import utils from '@/lib/utils'
import { Message } from 'element-ui'
import store from '@/store'

axios.defaults.baseURL = ''
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['token'] = localStorage.getItem('token')
axios.defaults.withCredentials = true
axios.defaults.timeout = 1000 * 60 * 10

// 在发送请求之前做些什么ack
axios.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('token')
  config.headers.requestId = utils.requestId()

  return config
})

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    if (typeof response.data === 'string') {
      return 'data' in response ? response.data || [] : response
    }
    const errCode =
      response.data && 'code' in response.data ? response.data.code : 'error'

    if (Array.prototype.toString.call(response.data) === '[object Blob]') {
      return response
    }

    if (errCode != 0) {
      if (
        response.config.url == '/purchase/pay/payOrder' &&
        JSON.parse(response.config.data).payType == 3
      ) {
        return
      }

      if (
        response.config.url == '/purchase/pay/payBalance' ||
        response.config.url == '/sys/menu/listMenus' ||
        response.config.url == '/goods/supplier-goods-invoice-info/import' ||
        response.config.url.includes(
          '/goods/supplier-goods-inventory-info/import-detail'
        )
      ) {
        return response.data
      }

      return onStatusError(response.data)
    }

    return response.data
  },
  // 对响应错误做点什么
  error => onStatusError(error)
)

async function onStatusError(error) {
  let response = error.response
  const err = response
    ? {
        code: error.response.data.code || 'error',
        message: error.response.data.msg || '服务器繁忙，请联系管理员'
      }
    : {
        code: error.code || 'error',
        message: error.msg || '服务器繁忙，请联系管理员'
      }
  response = response || {}
  const message =
    err.code == 401 || response.status == 401
      ? '登录已失效，请重新登录'
      : err.code == 403 || response.status == 403
      ? '无权限此操作'
      : err.message

  err.message = message

  Message.closeAll()

  Message({
    message: message,
    type: 'error',
    duration: 3 * 1000,
    showClose: true
  })
  if (err.code == 401 || response.status == 401) {
    await store.dispatch(
      `${MODULE_AUTHORIZATION.name}/${MODULE_AUTHORIZATION.ACT_LOGOUT}`
    )
    location = LOGIN_URL
  }

  return Promise.reject(err)
}
