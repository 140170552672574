import Vue from 'vue'
import utils from './utils'
import * as api from '@/api/index'
import '@/lib/element'
import '@/assets/css/reset.scss'
import '@/lib/request'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

import 'moment/locale/zh-cn'

import CommonComponents from '@/components/'
import CommonDirective from '@/directives/'
import CommonFilters from '@/filters/'
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})

CommonComponents.forEach(components => Vue.use(components))
Vue.use(CommonDirective)
Vue.use(CommonFilters)

Vue.prototype.interceptImg = src => {
  if (!src) return ''

  const base = '/gateway/file/getImg?fileId='
  return base + src
}

//只能中英文
Vue.prototype.forbidSpecial = function (value) {
  value = value
    .replace(
      /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
      ''
    )
    .replace(/\s/g, '')

  return value
}

//只能整数
Vue.prototype.onlyNum = function (value) {
  value = value.replace(/[^\d]/g, '').replace(/\s/g, '')
  return value
}

//只能中英文
Vue.prototype.onlyNumEng = function (value) {
  value = value.replace(/[^a-zA-Z0-9]/g, '').replace(/\s/g, '')
  return value || 0
}

//保留两位小数
Vue.prototype.keepTwoNum = function (value) {
  if (
    value.toString().indexOf('.') > 0 &&
    Number(value.toString().split('.')[1].length) > 2
  ) {
    value = Number(value).toFixed(2)
  }

  return value
}

export default function () {
  const plugins = {
    $api: api.default,
    $utils: utils
  }

  Object.keys(plugins).forEach(key => {
    Vue.prototype[key] = plugins[key]
  })
}

// 导入iconfont
const iconfontUrl = `//at.alicdn.com/t/font_2892333_efyo9kov6ud.js`

// if (process.env.NODE_ENV !== 'production') {
loadJs(iconfontUrl)
// }

function loadJs(url, callback) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  if (typeof callback != 'undefined') {
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState == 'loaded' || script.readyState == 'complete') {
          script.onreadystatechange = null
          callback && callback()
        }
      }
    } else {
      script.onload = function () {
        callback && callback()
      }
    }
  }
  script.src = url
  document.body.appendChild(script)
}
