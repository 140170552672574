import axios from 'axios'

//列表
export const getList = data => {
  return axios.post('/purchase/audit/page', data)
}

//审批
export const audit = data => {
  return axios.post(`/purchase/audit/audit`, data)
}

//批量审批
export const batchAudit = data => {
  return axios.post(`/purchase/audit/batch/audit`, data)
}

//获取详情
export const getDetail = params => {
  return axios.get(`/purchase/audit/${params.auditOrderNo}`)
}
