import axios from 'axios'

//列表
export const getList = data => {
  return axios.post('/purchase/discount/list', data)
}

//获取来源列表
export const getResourceList = data => {
  return axios.post('/purchase/discount/queryStore', data)
}

//查询门店
export const getStoreName = params => {
  return axios.get('/purchase/discount/queryStore', { params })
}

//优惠方式保存
export const waySave = data => {
  return axios.post('/purchase/discount/updateDiscount', data)
}

//返佣保存
export const amountSave = data => {
  return axios.post('/purchase/discount/updateReturnCommission', data)
}

//添加门店
export const operatorsConfigSave = data => {
  return axios.post('/purchase/discount/addStore', data)
}

//设置商品价格列表
export const getProductList = data => {
  return axios.post('/purchase/goods/querySupplierDiscountGoods', data)
}

//修改供应商优惠商品
export const priceSave = data => {
  return axios.post('/purchase/goods/updateGoodsDiscountPrice', data)
}
