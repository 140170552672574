import axios from 'axios'
import utils from '../lib/utils.js'

//订单总额查询
export const getAmountInfo = data => {
  return axios.post('/purchase/supply-purchase/order/statistical', data)
}

//采购列表
export const getPurchaseOrderList = params => {
  return axios.get('/purchase/supply-purchase/statistical/supplier/list', {
    params
  })
}

//退款列表
export const getRefundOrderList = data => {
  return axios.post('/order/refund/page', data)
}

//导出采购订单
export const exportPurchaseOrder = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/purchase/supply-purchase/down/supplierList', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}

//导出退款订单
export const exportRefundOrder = data => {
  return new Promise((resolve, reject) => {
    axios
      .post('/order/refund/order/refund/down', data, {
        responseType: 'blob'
      })
      .then(data => {
        utils.downFileAndFileName(data)
        resolve()
      })
      .catch(e => {
        reject(e)
      })
  })
}
