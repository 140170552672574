import axios from 'axios'

//类别列表
export const getClazzList = data => {
  return axios.post(`/goods/supply-secondary-goods-clazz/query`, data)
}

//删除类别
export const delClazz = data => {
  return axios.post(`/goods/supply-secondary-goods-clazz/delete`, data)
}

//获取类别
export const getClazz = data => {
  return axios.post(`/goodsClazz/query`, data)
}

//编辑类别
export const updateClazz = data => {
  return axios.post(`/goods/supply-secondary-goods-clazz/update`, data)
}

//添加类别
export const addClazz = data => {
  return axios.post(`/goods/supply-secondary-goods-clazz/add`, data)
}
