import axios from 'axios'

//列表
export const getList = data => {
  return axios.post('/purchase/middleman-goods/queryGoodsList', data)
}

//调价
export const updatePrice = data => {
  return axios.post('/purchase/middleman-goods/updatePrice', data)
}

//批量调价
export const updateBatchPrice = data => {
  return axios.post('/purchase/middleman-goods/batchUpdatePrice', data)
}

//上下架
export const uploadToStore = data => {
  return axios.post('/purchase/middleman-goods/updateState', data)
}

//批量上架
export const uploadToStoreBatch = data => {
  return axios.post('/purchase/middleman-goods/batchUp', data)
}

//删除
export const deleteHandler = data => {
  return axios.post('/purchase/middleman-goods/batchDeleteGoods', data)
}
