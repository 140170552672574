import axios from 'axios'

//订单列表
export const getGoodsBrandList = data => {
  return axios.post('/goodsBrand/query', data)
}

//删除品牌
export const delProduct = data => {
  return axios.post('/goodsBrand/delete', data)
}

//更新品牌
export const updateGoodsBrandInfo = data => {
  return axios.post(`/goodsBrand/update`, data)
}

//保存品牌
export const saveGoodsBrand = data => {
  return axios.post('/goodsBrand/add', data)
}

//品牌导入
export const projectBrandImport = data => {
  return axios.post('/goodsClazz/batchImport', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
