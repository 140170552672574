import axios from 'axios'

//分类列表
export const getClazzList = data => {
  return axios.post('/goodsClazz/query', data)
}

//新增分类
export const clazzSave = data => {
  return axios.post('/goodsClazz/add', data)
}

//编辑分类
export const clazzUpdate = data => {
  return axios.post('/goodsClazz/update', data)
}

//删除
export const clazzDelHandler = data => {
  return axios.post('/goodsClazz/delete', data)
}

//类别导入
export const projectClazzImport = data => {
  return axios.post('/goodsClazz/batchImport', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}
