import axios from 'axios'
import qs from 'qs'

//省
export const getProvince = () => {
  return axios.get('/purchase/region/lists/1/null')
}

//市
export const getCity = value => {
  return axios.get(`/purchase/region/lists/2/${value}`)
}

//区
export const getRegion = value => {
  return axios.get(`/purchase/region/lists/3/${value}`)
}

// 商品一级分类
export const getStoresClazz = data => {
  return axios.post('/goods/getStoresClazz', qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 门店
export const getStoresList = params => {
  return axios.get('/select/store/list', { params })
}

// 查询中间商侧的供应商
export const getSuppliers = data => {
  return axios.post('/purchase/middleman/pageSupplier', data)
}

//查询门店侧的供应商
export const getStoresSuppliers = params => {
  return axios.get('/purchase/supplier/listSuppliersByStoreId', { params })
}

//角色
export const getRoleAllList = params => {
  return axios.get(`/role/user-role/queryAllRoles`, { params })
}

//分组
export const getGroupAllList = data => {
  return axios.post(`/purchase/group-manage/groupManages`, data)
}

//供应商车辆
export const getCarAllList = params => {
  return axios.get(`/purchase/vehicle/queryVehicles`, { params })
}

//中间商车辆
export const getCarAllListMiddle = params => {
  return axios.get(`/logistics/middleman-vehicle/queryVehicles`, { params })
}

// 获取供应商的来源
export const getResourceList = data => {
  return axios.post('/purchase/discount/queryStore', data)
}

//跳转采购平台授权
export const auth = data => {
  return axios.post(`/purchase/sys/auth`, data)
}

//菜单栏
export const navList = params => {
  return axios.get(`/sys/menu/nav`, { params })
}
